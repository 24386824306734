import React, { FC } from "react";
import { useQuery, gql } from "@apollo/client";
import { Link, useParams } from "react-router-dom";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable"; // SelectColumnFilter,
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import Moment from "moment";
import "moment-timezone";
import {
  GetTransactionsQuery,
  TransactionFilterInput,
} from "../../gql/graphql";

export const TRANSACTIONS = gql`
  query GetTransactions(
    $where: TransactionFilterInput
    $take: Int
    $skip: Int
  ) {
    transactions(where: $where, take: $take, skip: $skip) {
      items {
        id
        type
        transactionType
        reservation {
          id
          source
        }
        parker {
          id
          firstName
          lastName
          email
        }
        vehicle {
          id
          plate
        }
        startUtc
        endUtc
        isPerm
        isOnSite
        amount
        site {
          id
          name
        }
        area {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

type Transaction = NonNullable<
  NonNullable<
    NonNullable<GetTransactionsQuery["transactions"]>["items"]
  >[number]
>;

const columnHelper = createColumnHelper<Transaction>();

const columns = [
  columnHelper.accessor("type", {
    header: "Transaction",
    meta: {
      filterType: "select",
      filterOptions: ["ADHOC", "PERM", "VISITOR", "PUBLIC"],
    },
    cell: (info) => {
      let value = info.getValue() as string;
      value = value?.replace("_RESERVATION", "");
      return <Link to={"/transactions/" + info.row.original.id}>{value}</Link>;
    },
  }),
  columnHelper.accessor("site.name", {
    header: "Site",
    // Filter: SelectColumnFilter,
    cell: (info) => {
      const value = info.getValue();
      return value ? (
        <Link to={"/sites/" + info.row.original.site?.id}>
          {info.renderValue()}
        </Link>
      ) : (
        <>no site</>
      );
    },
  }),
  // columnHelper.accessor("area.name", {
  //   header: "Area",
  //   // Filter: SelectColumnFilter,
  //   cell: (info) => {
  //     const value = info.getValue();
  //     return value ? (
  //       <Link to={"/sites/" + info.row.original.site?.id + "/status"}>
  //         {info.renderValue()}
  //       </Link>
  //     ) : (
  //       <>no area</>
  //     );
  //   },
  // }),

  {
    accessorFn: (row: any) => row.area?.name ?? "", // doing it this way because the columnHelper.accessor is not working for deep nested fields undefined
    header: "Area",
    // Filter: SelectColumnFilter,
    cell: (info: any) => {
      const value = info.getValue();
      return value ? (
        <Link to={"/sites/" + info.row.original.site?.id + "/status"}>
          {info.renderValue()}
        </Link>
      ) : (
        <>no area</>
      );
    },
    meta: {
      filterType: "text",
    },
  },

  // columnHelper.accessor("parker.email", {
  //   header: "Parker",
  //   cell: (info) => {
  //     const value = info.getValue();
  //     return (
  //       <>
  //         {value ? (
  //           <Link to={"/parkers/" + info.row.original.parker?.id}>
  //             {info.renderValue()}
  //           </Link>
  //         ) : (
  //           ""
  //         )}
  //       </>
  //     );
  //   },
  // }),

  {
    accessorFn: (row: any) => row.parker?.email ?? "", // doing it this way because the columnHelper.accessor is not working for deep nested fields undefined
    header: "Parker",
    cell: (info: any) => {
      const value = info.getValue();
      return (
        <>
          {value ? (
            <Link to={"/parkers/" + info.row.original.parker?.id}>
              {info.renderValue()}
            </Link>
          ) : (
            ""
          )}
        </>
      );
    },
  },

  // columnHelper.accessor("vehicle.plate", {
  //   header: "Vehicle",
  //   cell: (info) => {
  //     const value = info.getValue() ?? "";
  //     return value ? (
  //       <Link to={"/vehicles/" + info.row.original.vehicle?.id}>
  //         {info.renderValue()}
  //       </Link>
  //     ) : null;
  //   },
  // }),

  {
    accessorFn: (row: any) => row.vehicle?.plate ?? "", // doing it this way because the columnHelper.accessor is not working for deep nested fields undefined
    header: "Vehicle",
    cell: (info: any) => {
      const value = info.getValue();
      return value ? (
        <Link to={"/vehicles/" + info.row.original.vehicle?.id}>
          {info.renderValue()}
        </Link>
      ) : null;
    },
  },

  // {
  //   Header: 'Project',
  //   accessor: 'projectId',
  //   Filter: <></>,
  //   cell: ({value}) => <Link to={ '/preinstalls/' + value }>{value}</Link>
  // },
  // {
  //   Header: 'Modified',
  //   accessor: 'modifiedUtc',
  //   Filter: <></>,
  //   cell: ({ value }) => <>{Moment.utc(value).tz(Moment.tz.guess()).format("YYYY-MM-DD HH:mm")}</>
  // },
  // {
  //   Header: 'Status',
  //   accessor: 'orderStatus',
  //   Filter: SelectColumnFilter,
  //   // Cell: ({ value }) => <OrderStatus status={value}></OrderStatus>
  // },

  columnHelper.accessor("startUtc", {
    header: "Start",
    meta: {
      filterType: "date",
    },
    enableGlobalFilter: false,
    cell: (info) => {
      const value = info.getValue();
      return (
        <>
          {value &&
            Moment.utc(value.substring(0, 19))
              .tz(Moment.tz.guess())
              .format("DD MMM YYYY HH:mm")}
        </>
      );
    },
  }),
  columnHelper.accessor("endUtc", {
    header: "End",
    meta: {
      filterType: "date",
    },
    enableGlobalFilter: false,
    cell: (info) => {
      const value = info.getValue();
      return (
        <>
          {value &&
            Moment.utc(value.substring(0, 19))
              .tz(Moment.tz.guess())
              .format("DD MMM YYYY HH:mm")}
        </>
      );
    },
  }),

  //   {
  //     Header: 'Loc Id',
  //     accessor: 'locationId',
  //     Filter: <></>
  //   },
  //   {
  //     Header: 'Address',
  //     accessor: 'jobLocation',
  //     sortable: false,
  //     disableSortBy: true
  //   },
  //   {
  //     Header: 'Customer Ref',
  //     accessor: 'customerRef',
  //   },

  columnHelper.accessor("amount", {
    header: "Amount",
    meta: {
      filterType: "range",
    },
    cell: (info) => {
      const value = info.getValue();
      return <>{value ? "$" + value.toFixed(2) : null}</>;
    },
  }),
];

export const Transactions: FC<{
  parkerId?: string;
  reservationId?: string;
}> = ({ parkerId, reservationId }) => {
  const { parkerId: pId, reservationId: rId } = useParams();
  if (!parkerId) parkerId = pId;
  if (!reservationId) reservationId = rId;
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  // default filter
  // () => {
  //   var lastWeek = new Date();
  //   lastWeek.setDate(lastWeek.getDate() - 7);
  //   return [
  //     {
  //       id: "startUtc",
  //       value: lastWeek.toISOString().substring(0, 10) + "T00:00:00Z",
  //     },
  //   ];
  // }

  const where = React.useMemo(() => {
    const where: TransactionFilterInput = {};
    if (parkerId) {
      where.parkerId = { eq: parkerId };
    }
    if (reservationId) {
      where.reservationId = { eq: reservationId };
    }
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "startUtc":
          where[filter.id] = { gte: filter.value };
          break;
        case "endUtc":
          where[filter.id] = { lte: filter.value };
          break;
        case "amount":
          if (filter.value != null) {
            const [min, max] = filter.value as [number?, number?];
            if (min == null && max == null) {
              break;
            }
            const f: TransactionFilterInput["amount"] = {};
            if (min != null) {
              f.gte = min;
            }
            if (max != null) {
              f.lte = max;
            }
            where[filter.id] = f;
          }
          break;
        case "type":
          switch (filter.value) {
            case "PERM":
              where.isPerm = { eq: true };
              // where.transactionType = { eq: "PermanentReservation" };
              break;
            case "VISITOR":
              // where.isPerm = { neq: true };
              // where.reservation = { source: { eq: "admin" } };
              where.transactionType = { eq: "VisitorReservation" };
              break;
            case "PUBLIC":
              // where.isPerm = { neq: true };
              // where.reservationId = { neq: null };
              // where.reservation = { source: { neq: "admin" } };
              where.transactionType = { eq: "PublicReservation" };
              break;
            case "ADHOC":
              // where.isPerm = { neq: true };
              // where.reservationId = { eq: null };
              where.transactionType = { eq: "Adhoc" };
              break;
          }
          break;
        case "vehicleId":
        case "parkerName":
        case "siteName":
        case "areaName":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [parkerId, reservationId, columnFilters]);

  const { data, loading } = useQuery<GetTransactionsQuery>(TRANSACTIONS, {
    fetchPolicy: "no-cache",
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
  });

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        {!parkerId && !reservationId && <h1 className="h2">Transactions</h1>}
      </div>

      <ShareParkTable
        data={data?.transactions ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Transaction>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
      />
    </div>
  );
};
