import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiUrl } from "../config";

const BuildVersion = () => {
  // using react query, get /api/health/version

  let version = "Unknown";

  const { data, isFetching } = useQuery({
    queryKey: ["version"],
    queryFn: () => axios.get(`https://${apiUrl}/health/buildversion`),
  });

  if (data) {
    version = data.data;
  }

  return (
    <span>
      <strong>{isFetching ? "...Loading" : version}</strong>
    </span>
  );
};
export default BuildVersion;
