import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { TextInput } from "../../components/TextInput";
import { gql, useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { Testing } from "../Testing/Testing";
import { GetVehicleQuery } from "../../gql/graphql";

const GET = gql`
  query GetVehicle($vehicleId: UUID!) {
    vehicles(where: { id: { eq: $vehicleId } }) {
      items {
        id
        parkerId
        parker {
          id
          email
          firstName
          lastName
        }
        plate
        make
        model
        colour
        state
        isActive
        isElectric
      }
    }
  }
`;

const UPDATE = gql`
  mutation UpdateVehicle($vehicle: VehicleInput!) {
    updateVehicle(vehicle: $vehicle) {
      id
      parkerId
      parker {
        id
        email
        firstName
        lastName
      }
      plate
      make
      model
      colour
      state
      isActive
      isElectric
    }
  }
`;

const states = ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

type VehicleType = NonNullable<
  NonNullable<GetVehicleQuery["vehicles"]>["items"]
>[number];

export const Vehicle = () => {
  let { parkerId, vehicleId, tab } = useParams();

  const parkerUrl = parkerId ? `/parkers/${parkerId}` : "";

  const navigate = useNavigate();

  const [vehicle, setVehicle] = useState<VehicleType | null>(null);
  const { data } = useQuery<GetVehicleQuery>(GET, {
    variables: {
      vehicleId:
        vehicleId === "new"
          ? "00000000-0000-0000-0000-000000000000"
          : vehicleId,
    },
    fetchPolicy: "network-only",
  });

  const [updateVehicle] = useMutation(UPDATE);

  useEffect(() => {
    if (vehicleId === "new") {
      setVehicle({
        id: parkerId || "00000000-0000-0000-0000-000000000000",
        parkerId: parkerId || undefined,
        plate: "",
        make: "",
        model: "",
        colour: "",
        state: "",
        isActive: true,
        isElectric: false,
      });
    }
    if (data?.vehicles?.items?.length === 1) {
      setVehicle(data.vehicles.items[0]);
    }
  }, [data, vehicleId, parkerId]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    if (vehicle) {
      updateVehicle({
        variables: {
          vehicle: {
            id: vehicle.id,
            ...data,
            parkerId: vehicle.parkerId,
            isActive: vehicle.isActive,
            isElectric: vehicle.isElectric,
          },
        },
      }).then((x) => {
        if (x.data.updateVehicle) {
          toast(`Vehicle: ${x.data.updateVehicle.plate} saved`, {
            type: toast.TYPE.SUCCESS,
            autoClose: 3000,
          });

          navigate(parkerUrl + "/vehicles");
        }
      });
    }
  };

  return (
    <>
      {vehicle && (
        <>
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">
              Vehicle{" "}
              {vehicle.parkerId && <span> - {vehicle.parker?.email}</span>}
            </h1>
          </div>

          <ul className="nav nav-tabs mb-3">
            <li className="nav-item">
              <Link
                className={`nav-link ${!tab ? "active" : ""}`}
                to={`/vehicles/${vehicleId}`}
              >
                Details
              </Link>
            </li>
            {vehicle.id !== "00000000-0000-0000-0000-000000000000" && (
              <li className="nav-item">
                <Link
                  className={`nav-link text-danger ${
                    tab === "testing" ? "active" : ""
                  }`}
                  to={`/vehicles/${vehicleId}/testing`}
                >
                  Testing
                </Link>
              </li>
            )}
          </ul>

          {!tab && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={
                "needs-validation" +
                (Object.keys(errors).length > 0 ? " was-validated" : "")
              }
              noValidate
            >
              {!parkerId &&
                vehicle.id === "00000000-0000-0000-0000-000000000000" && (
                  <>
                    <div className="alert alert-warning" role="alert">
                      <strong>Careful!</strong> Adding a vehicle this way will
                      create a vehicle unlinked to a parker.
                    </div>
                  </>
                )}

              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="plate"
                    label="Plate"
                    defaultValue={vehicle.plate ?? ""}
                    placeholder="Plate"
                    register={register}
                    config={{ required: true }}
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <TextInput
                    name="make"
                    label="Make"
                    defaultValue={vehicle.make ?? ""}
                    placeholder="Make"
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
                <div className="col-md">
                  <TextInput
                    name="model"
                    label="Model"
                    defaultValue={vehicle.model ?? ""}
                    placeholder="Model"
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md">
                  <TextInput
                    name="colour"
                    label="Colour"
                    defaultValue={vehicle.colour ?? ""}
                    placeholder="Colour"
                    register={register}
                    required={false}
                    errors={errors}
                  />
                </div>
                <div className="col-md d-flex align-items-center">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isElectric"
                      autoComplete="off"
                      defaultChecked={vehicle.isElectric}
                      onClick={() =>
                        setVehicle({
                          ...vehicle,
                          isElectric: !vehicle.isElectric,
                        })
                      }
                    />
                    <label className="form-check-label" htmlFor="isElectric">
                      Is Electric?
                    </label>
                  </div>
                </div>
                <div className="col-md">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <select
                    className="form-select"
                    defaultValue={vehicle.state ?? ""}
                    {...register("state")}
                  >
                    <option value="">Choose a state</option>
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">Please choose a state.</div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md">
                  <input
                    type="checkbox"
                    className="btn-check"
                    id="btn-check-outlined"
                    autoComplete="off"
                    onClick={() =>
                      setVehicle({ ...vehicle, isActive: !vehicle.isActive })
                    }
                  />
                  <label
                    className={
                      vehicle.isActive ? "btn btn-success" : "btn btn-danger"
                    }
                    htmlFor="btn-check-outlined"
                  >
                    {vehicle.isActive ? "Active" : "Inactive"}
                  </label>
                </div>
              </div>

              <div className="mt-3 mb-3">
                <input className="btn btn-primary" type="submit" value="Save" />{" "}
                <Link to={parkerUrl + "/vehicles"} className="btn btn-link">
                  Cancel
                </Link>
              </div>
            </form>
          )}

          {tab === "testing" && (
            <>
              {vehicle.id !== "00000000-0000-0000-0000-000000000000" &&
                vehicle.plate !== null && (
                  <>
                    <Testing plate={vehicle.plate} />
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
};
