import React from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import Moment from "moment";
import "moment-timezone";
import { EventFilterInput, GetEventsQuery } from "../../gql/graphql";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import ShareParkTable, {
  usePaginationAndFilters,
} from "../../components/table/ShareParkTable";
import { ShareparkDateTime } from "../../components/LocalTime";

export const GET = gql`
  query GetEvents($where: EventFilterInput, $take: Int!, $skip: Int!) {
    events(where: $where, take: $take, skip: $skip) {
      totalCount
      items {
        id
        createdUtc
        type
        message
        transaction {
          id
          startUtc
          parker {
            id
            firstName
            lastName
            fullName
          }
        }
        site {
          id
          name
        }
      }
    }
  }
`;

type Event = NonNullable<
  NonNullable<NonNullable<GetEventsQuery["events"]>["items"]>[number]
>;

const columnHelper = createColumnHelper<Event>();

const columns = [
  columnHelper.accessor("createdUtc", {
    header: "When",
    cell: (info) => {
      return <ShareparkDateTime timestamp={info.getValue()} />;
    },
  }),
  columnHelper.accessor("site.name", {
    header: "Site",
    cell: (info) => {
      return (
        <Link to={`/sites/${info.row.original?.site?.id}`}>
          {info.renderValue()}
        </Link>
      );
    },
  }),
  columnHelper.accessor("transaction.startUtc", {
    header: "Transaction",
    cell: (info) => {
      return (
        <Link to={`/transactions/${info.row.original?.transaction?.id}`}>
          {info.row.original?.transaction?.startUtc
            ? Moment.utc(
                info.row.original?.transaction?.startUtc.substring(0, 19)
              )
                .tz(Moment.tz.guess())
                .format("DD MMM YYYY HH:mm")
            : info.row.original?.transaction?.id}
        </Link>
      );
    },
  }),

  // columnHelper.accessor("transaction.parker.fullName", {
  //   header: "Parker",
  //   cell: (info) => {
  //     return (
  //       <Link to={`/parkers/${info.row.original?.transaction?.parker?.id}`}>
  //         {info.row.original?.transaction?.parker?.fullName}
  //       </Link>
  //     );
  //   },
  // }),

  {
    accessorFn: (row: any) => row.original?.transaction?.parker?.fullName ?? "", // doing it this way because the columnHelper.accessor is not working for deep nested fields undefined
    header: "Parker",
    // Filter: SelectColumnFilter,
    cell: (info: any) => {
      const value = info.row.original?.transaction?.parker?.fullName ?? "";

      return value ? (
        <Link to={`/parkers/${info.row.original?.transaction?.parker?.id}`}>
          {value}
        </Link>
      ) : (
        <>no parker</>
      );
    },
    meta: {
      filterType: "text",
    },
  },

  columnHelper.accessor("type", {
    header: "Type",
  }),
  columnHelper.accessor("message", {
    header: "Message",
  }),
];

export const AbnormalEvents = () => {
  const { pagination, setPagination, columnFilters, setColumnFilters } =
    usePaginationAndFilters();
  const where = React.useMemo(() => {
    const where: EventFilterInput = { type: { eq: "ABNORMAL" } };
    for (const filter of columnFilters) {
      switch (filter.id) {
        case "site_name":
          if (filter.value != null) {
            where.site = { name: { contains: String(filter.value) } };
          }
          break;
        case "transaction_startUtc":
          if (filter.value != null) {
            where.transaction = where.transaction ?? {};
            where.transaction.startUtc = { gte: String(filter.value) };
          }
          break;
        case "transaction_parker.fullName":
          if (filter.value != null) {
            where.transaction = where.transaction ?? {};
            where.transaction.parker = {
              or: [
                { firstName: { contains: String(filter.value) } },
                { lastName: { contains: String(filter.value) } },
              ],
            };
          }
          break;
        case "type":
        case "message":
          if (filter.value != null) {
            where[filter.id] = { contains: String(filter.value) };
          }
          break;
      }
    }
    return where;
  }, [columnFilters]);

  const { data, loading } = useQuery<GetEventsQuery>(GET, {
    variables: {
      take: pagination.pageSize,
      skip: pagination.pageSize * pagination.pageIndex,
      where,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <div className="border-top mt-3">
      <ShareParkTable
        data={data?.events ?? { items: [], totalCount: 0 }}
        columns={columns as ColumnDef<Event>[]}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        loading={loading}
      />
    </div>
  );
};
